<template>
  <div>
    <FamiliesTable />
  </div>
</template>

<script>
import FamiliesTable from '../components/FamiliesTable.vue'

export default {
  components: {
    FamiliesTable
  }
}
</script>
